@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans" !important;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  margin: 0;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.login-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #52c2b5 0%, #2b1cda 100%);
  overflow: hidden;
}
.login-background {
  width: 100%;
  position: absolute;
}
.login-down-background {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.login-main-container {
  z-index: 2;
  background: #ffffff;
  border: 1px solid #e8e0e0;
  border-radius: 24px;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 47.28px;
  padding-bottom: 47.28px;
  margin-top: -170px;
}
.login-main-container h1 {
  color: #7e8488;
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 500;
}
.login-logo {
  width: 31.39px;
  margin-bottom: 8px;
}

.custom-textInput {
  background: #ffffff;
  border: 1px solid #e8e0e0;
  border-radius: 10px;
  height: 36px;
  padding-left: 16px;
}

.custom-textInput::placeholder {
  color: #7e8488;
  font-size: 14px;
  font-weight: 500;
  /* padding-left: 16px; */
}

.custom-textInput-error {
  border: 1px solid #ff1212;
}

.error-msg {
  color: #ff1212;
  font-size: 14px;
}
.login-input-row {
  margin-bottom: 24px;
}

.login-textInput {
  width: 300px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form a {
  color: #00a693 !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}

.custom-submitButton {
  background: #52c2b5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 32px;
  height: 38px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #52c2b5;
  cursor: pointer;
  height: auto;
}

.custom-submitButton:disabled {
  background: #cccccc;
  box-shadow: none;
  color: #f2f4ff !important;
  border: 1px solid #cccccc;
  cursor: not-allowed;
}

.login-background-mobile-top {
  display: none;
  position: absolute;
}
.login-background-mobile-bottom {
  display: none;
  position: absolute;
}

.page-layout {
  width: 100%;
  display: flex;
}
.page-layout-content-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 65px;
}
.page-layout-header {
  margin-left: 40px;
  margin-right: 51.5px;
}

.header-notification-icon {
  position: relative;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
  border-radius: 14px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.header-notification-icon-container {
  display: flex;
}

.header-notification-icon-container svg {
  width: auto;
  height: auto;
}

.header-notification-icon:hover,
.header-notification-icon:focus {
  background-color: #fdeef1;
}

.header-notification-icon-container:hover path,
.header-notification-icon-container:focus path {
  fill: #ee324c;
}

.header-edit-profile-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
  border-radius: 14px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.header-conturi-asociate {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
  border-radius: 14px;
  /*width: 40px;*/
  padding: 15px;
  height: 40px;
  margin-right: 50px;
  cursor: pointer;
}

.header-edit-profile-icon-container svg {
  /* width: 29px; */
  width: auto;
  height: auto;
}
.header-edit-profile-icon-container:hover,
.header-edit-profile-icon-container:focus {
  background-color: #fdeef1;
}
.header-edit-profile-icon-container:hover path,
.header-edit-profile-icon-container:focus path {
  fill: #ee324c;
}

.header-edit-profile-content {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  background-color: white;
  right: 0;
  border: 1px solid #e8e0e0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  padding: 15px 10.5px;
  opacity: 0;
  transform: translateY(-20px);
  min-width: 140px;
  transition:
    opacity 0.65s ease,
    transform 0.65s ease;
}

.header-conturi-asociate-content {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  background-color: white;
  right: 0;
  border: 1px solid #e8e0e0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  padding: 15px 10.5px;
  opacity: 0;
  transform: translateY(-20px);
  min-width: 140px;
  transition:
    opacity 0.65s ease,
    transform 0.65s ease;
}

.header-edit-profile-content div:nth-of-type(2) {
  display: none;
}

.header-edit-profile {
  position: relative;
}

.header-edit-profile:hover .header-edit-profile-content {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.header-edit-profile:hover .header-conturi-asociate-content {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.header-edit-profile-div {
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #7e8488;
  margin-top: 9px;
}

.header-edit-profile-div-selected {
  background-color: #fdeef1;
  border-radius: 10px;
  color: #ee324c;
  padding: 7px;
}

.header-edit-profile-div:hover {
  background-color: #fdeef1;
  border-radius: 10px;
  color: #ee324c;
  padding: 7px;
}

.left-menu {
  display: flex;
  flex-direction: column;
  flex: 0.2;
  min-height: 100vh;
  border-right: 1px solid #e8e0e0;
}
.menu-logo {
  width: 123.7px;
  margin-top: 52.48px;
  margin-left: 55.5px;
  margin-bottom: 42.88px;
}

.menu-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 55px;
  margin-bottom: 43px;
  cursor: pointer;
}
.menu-row svg {
  width: auto;
}
.menu-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-row-selected div:nth-of-type(1) {
  background: #fdeef1;
  border-radius: 14px;
  width: 40px;
  height: 40px;
}
.menu-row-selected {
  padding-left: 47px;
}
.menu-row-selected path {
  fill: #ee324c;
}
.menu-row p {
  font-size: 16px;
  padding-left: 17px;
  color: #7e8488;
}

.menu-row-selected img {
  width: 35px;
  background-color: #fdeef1;
  padding: 5px;
  margin-left: -6px;
  border-radius: 9.5px;
}
.menu-row-selected p {
  color: #1d1d1d;
  font-weight: 500;
  padding-left: 9.5px;
}

.container-page-content {
  display: flex;
  flex-direction: column;
  flex: 0.8;
  margin-left: 40px;
  margin-right: 51px;
}

.footer {
  display: none;
  justify-content: space-around;
}
.footer-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 12.5px;
  height: 40px;
  width: 40px;
}
.footer-icon-container svg {
  width: auto;
}
.footer-icon-container-selected {
  background: #fdeef1;
  border-radius: 14px;
}
.footer-icon-container-selected path {
  fill: #ee324c;
}
.dashboard-content-div {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-sold-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #ee324c;
  border-radius: 24px;
  padding: 36.96px;
  max-width: 296px;
  margin-bottom: 32px;
  order: 2;
  width: 22%;
  margin-left: auto;
}
.dashboard-sold-container img {
  width: 157px;
  margin-bottom: 16px;
}
.dashboard-sold-container div:nth-of-type(1) {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.dashboard-sold-container div:nth-of-type(2) {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  line-height: 52px;
}
.dashboard-sold-container div:nth-of-type(3) {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  max-width: 222px;
}
.dashboard-plateste {
  color: #1d1d1d;
  width: 100%;
  margin-top: 16px;
  background: #ffffff;
}
.dashboard-locuri-consum {
  padding-top: 25.9px;
  padding-bottom: 29.19px;
  padding-left: 23px;
  padding-right: 23px;
  background: #ffffff;
  border: 1px solid #e8e0e0;
  border-radius: 24px;
  max-width: 296px;
  order: 4;
  width: 22%;
  margin-left: auto;
  align-self: flex-start;
}
.dashboard-locuri-consum h2 {
  color: #1d1d1d;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 14px;
}

.locuri-consum-content {
  display: flex;
  flex-direction: column;
}
.locuri-consum-content p:nth-of-type(1) {
  color: #1d1d1d;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}
.locuri-consum-content p:nth-of-type(2) {
  color: #7e8488;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.locuri-consum-content p:nth-of-type(3) {
  color: #7e8488;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.locuri-consum-icons {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.gaz-curent-icon {
  background-color: #f2f4ff;
  border-radius: 50%;
  width: 44px;
  display: flex;
  padding: 8px;
  align-items: center;
}
.gaz-curent-icon-electricitate {
  background: #fff7ec;
}
.gaz-curent-icon img {
  width: 28px;
  display: flex;
  flex: 1;
}

.toate-loc-consum-btn {
  border: 1px solid #00a693;
  background: #ffffff;
  color: #00a693;
  width: 100%;
  margin-top: 31.93px;
}

.icon-notification {
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: #f7f7f7;
  border-radius: 14px;
}
.icon-notification-white-bg {
  background-color: #ffffff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}
.icon-notification-red-circle {
  position: absolute;
  background-color: #d40000;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 2.5px;
  right: 2.5px;
}
.icon-notification-profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.one-notification-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f7f7f7;
  min-height: 40px;
  margin-top: 20px;
  padding-left: 18px;
  border: 1px solid #e8e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
}
.one-notification-div p {
  font-size: 14px;
  color: #1d1d1d;
  font-weight: 500;
}
.one-notification-div img {
  padding-right: 6px;
}
.welcome-and-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.welcome-container {
  margin-top: 15.94px;
  font-weight: 500;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.welcome-container p:nth-child(1) {
  font-size: 16px;
  line-height: 21px;
  color: #7e8488;
  font-weight: 400;
}
.welcome-container p:nth-child(2) {
  font-size: 24px;
  color: #1d1d1d;
  font-weight: 500;
  line-height: 31px;
}
.welcome-container p:nth-child(3) {
  font-size: 14px;
  color: #7e8488;
  font-weight: 400;
  line-height: 18px;
}

.pageName-and-text {
  display: none;
}

.pageName-and-text img {
  width: 33px;
}
.pageName-and-text p {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
}

.grafic-facturi {
  border: 1px solid #e8e0e0;
  border-radius: 24px;
  padding-top: 33.81px;
  order: 1;
  width: 77%;
  margin-bottom: 32px;
}
.grafic-facturi-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.grafic-facturi-content h2 {
  color: #1d1d1d;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  padding-left: 40.5px;
}
.grafic-facturi-selectors {
  display: flex;
  padding-right: 39.5px;
}

.container-contul-meu {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
}
.content-contul-meu-row {
  display: flex;
  width: 100%;
}

.my-account-div {
  border: 1px solid #e8e0e0;
  border-radius: 24px;
  padding-top: 26.31px;
  padding-left: 23.5px;
  padding-right: 23.5px;
  padding-bottom: 32.69px;
  width: 100%;
}

.my-account-div h2 {
  color: #1d1d1d;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
}

.my-account-div-content {
  display: flex;
  margin-top: 16px;
  margin-bottom: 37px;
}

.my-account-details {
  width: 33%;
  margin-right: 32px;
}

.my-account-details p:nth-child(1) {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7e8488;
}

.my-account-details p:nth-child(2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1d1d1d;
}

.my-account-line {
  height: 1px;
  background-color: #e8e0e0;
}

.buton-bg-alb-green {
  border: 1px solid #00a693;
  color: #00a693;
  background: #ffffff;
  box-shadow: none;
  line-height: 18px;
}
.autocitire-adauga-autocitire-button:disabled {
  background: #cccccc;
  box-shadow: none;
  color: #f2f4ff !important;
  border: 1px solid #cccccc;
  cursor: not-allowed;
}
.buton-bg-alb-red {
  background: #ffffff;
  border: 1px solid #fdeef1;
  color: #ee324c;
  box-shadow: none;
  line-height: 18px;
}

.my-account-buttons {
  display: flex;
  margin-top: 37px;
}
.my-account-buttons :nth-child(2) {
  margin-left: 32px;
}

.panou-recomandari-div {
  border: 1px solid #e8e0e0;
  border-radius: 24px;
  padding-top: 26.31px;
  padding-left: 23.5px;
  padding-right: 23.5px;
  padding-bottom: 32.69px;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  width: 36.1%;
}

.panou-recomandari-div h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #1d1d1d;
  margin-bottom: 16px;
}
.panou-recomandari-row {
  display: flex;
}
.pr-row-1 {
  margin-bottom: 16px;
}
.pr-row-2 {
  margin-bottom: 37px;
}
.panou-recomandari-details {
  display: flex;
  flex-direction: column;
}

.panou-recomandari-details p:nth-child(1) {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7e8488;
}
.panou-recomandari-details p:nth-child(2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1d1d1d;
}

.panou-recomandari-row div:nth-child(1) {
  margin-right: 64px;
}

.contractele-mele-div {
  margin-top: 32px;
  border: 1px solid #e8e0e0;
  border-radius: 24px;
  padding-top: 22px;
  padding-left: 22.5px;
  padding-right: 22.5px;
  padding-bottom: 36.1px;
  display: flex;
  flex-direction: column;
}

.contractele-mele-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.contractele-mele-header h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #1d1d1d;
  /* margin-bottom: 40.4px; */
}

.contractele-mele-content-card {
  display: flex;
  flex-direction: column;
}
.contractele-mele-card-title {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.contractele-mele-card-incetat {
  display: flex;
  background: #fdeef1;
  border-radius: 100px;
  align-self: flex-start;
}

.contractele-mele-content-card h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.contractele-mele-content-card p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1d1d1d;
}
.contractele-mele-content-card p:nth-of-type(1) {
  color: #7e8488;
}
.contractele-mele-content-card p:nth-of-type(2) {
  color: #7e8488;
  font-size: 12px;
  line-height: 16px;
}
.contractele-mele-card-incetat p {
  color: #ee324c !important;
  padding: 4px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.contractele-mele-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.contractele-mele-card {
  display: flex;
  margin-top: 40.4px;
  /* margin-right: 85px; */
  max-width: 379px;
}

.gaz-curent-icon-contracte {
  background-color: #f2f4ff;
  border-radius: 50%;
  min-width: 75px;
  max-height: 75px;
  padding: 16px;
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.gaz-curent-icon-electricitate-contracte {
  background: #fff7ec;
}
.gaz-curent-icon-contracte img {
  width: 28px;
  display: flex;
  flex: 1;
}
.two-p-div p {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 18px !important;
}
.two-p-div p:nth-of-type(1) {
  color: #7e8488;
}
.two-p-div p:nth-of-type(2) {
  color: #1d1d1d;
}
.first-2-p-div {
  margin-right: 64px;
}
.contactele-mele-container-second {
  display: flex;
  margin-top: 32px;
}
.contactele-mele-container-third {
  display: flex;
  margin-top: 32px;
}

.utilizatori-div {
  margin-top: 32px;
  border: 1px solid #e8e0e0;
  border-radius: 24px;
  padding-top: 25.5px;
  padding-left: 22.5px;
  padding-right: 22.5px;
  padding-bottom: 29.5px;
  display: flex;
  flex-direction: column;
}

.utilizatori-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 40px;
}
.utilizatori-header h2 {
  color: #1d1d1d;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}
.utilizatori-btn {
  display: flex;
  align-items: center;
}
.utilizatori-btn img {
  margin-right: 10px;
}
.utilizatori-content {
  display: flex;
  flex-direction: column;
}
.utilizatori-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}

.utilizatori-btns {
  display: flex;
}
.utilizatori-btns :nth-child(1) {
  margin-right: 16px;
}
.utilizatori-btn-modifica {
  padding: 10px 31px;
  margin: auto;
}
.utilizatori-btn-dezactiveaza {
  padding: 10px 26px;
  margin: auto;
}

.utilizatori-bg {
  display: flex;
  background: #f7f7f7;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  min-width: 60px;
  border-radius: 50%;
}
.utilizatori-bg p {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #7e8488;
}
.utilizatori-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}

.utilizatori-details :nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.utilizatori-details :nth-child(2) {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7e8488;
}
.p-datatable {
  font-family: "DM Sans" !important;
}

.autocitire-cards-container {
  display: flex;
  margin-bottom: 32px;
}
.autocitire-cards-container .autocitire-card:nth-child(2) {
  margin-left: 24px;
}
.autocitire-card {
  display: flex;
  border: 1px solid #e8e0e0;
  border-radius: 24px;
  padding-left: 38px;
  padding-right: 38px;
  padding-top: 29.46px;
  padding-bottom: 29.25px;
  min-width: 170px;
}
.autocitire-card-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f4ff;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-right: 24px;
}
.autocitire-card-image-container-curent {
  background: #fff7ec;
}
.autocitire-card-image-container img {
  width: 52px;
}
.autocitire-card-content {
  margin-top: 20.29px;
}
.autocitire-card-content h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.autocitire-card-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7e8488;
  margin-bottom: 24px;
}

/* AUTOCITIRE TABLE */
.autocitire_table_header {
  display: flex;
  flex-direction: column;
}
.autocitire_table_selectors {
  display: flex;
  flex-direction: row;
}
.autocitire_table_selectors :nth-child(2) {
  margin-left: 40px;
}
.autocitire_table_selectors :nth-child(3) {
  margin-left: 40px;
}
.autocitire_table_row {
  position: relative;
  margin-bottom: 33px;
}
.autocitire_table_option {
  cursor: pointer;
}
.autocitire_table_option p {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #7e8488;
}
.autocitire_table_option-selected p {
  color: #1d1d1d;
  margin-bottom: 24.61px;
}
.autocitire_table_option-selected {
  border-bottom: 4px solid #ee324c;
  z-index: 1;
}
.autocitire-table-line {
  height: 2px;
  background-color: #e8e0e0;
  position: absolute;
  width: 100%;
  bottom: 1px;
}
.autocitire-table-autocitiri-export {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.autocitire-table-autocitiri-export :nth-child(1) {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.autocitire_table_status_icon {
  display: flex;
  margin: auto;
  width: 21px;
}

.autocitire_table_tip_icon {
  width: 40px;
}
.autocitire_table_data {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.autocitire_table_loc_de_consum {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7e8488;
}
.autocitire_table_consum_total {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.autocitire_table_autocitiri_trimise {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.autocitire_table_status_div {
  display: flex;
}
.autocitire_table_status_div p {
  display: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7e8488;
  margin: auto;
}
.autocitire-adauga-autocitire-button {
  color: #00a693 !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-right: 10px;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.autocitire-table
  > div.p-datatable-header {
  background: #fff;
  border: 1px solid #dee2e6;
  border-width: 1px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom: 0px;
  padding-bottom: 20px;
  padding-left: 39px;
  padding-right: 39px;
  padding-top: 34px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.autocitire-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td {
  border: 0px;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.autocitire-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th {
  border: 0px;
  border-bottom: 1px solid #e8e0e0;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7e8488;
  box-shadow: none;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.autocitire-table
  > div.p-datatable-wrapper {
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  padding-left: 30px;
  padding-right: 30px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div
  > div.p-paginator.p-component.p-paginator-bottom {
  border: 1px solid #e8e0e0;
  border-top: 0px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.my-account-loading-div {
  padding-bottom: 32.69px;
}

.my-account-no-invoices {
  margin-left: 42px;
  padding-bottom: 10px;
}

.dashboard_table_tip_icon {
  width: 40px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table {
  order: 3;
  width: 77%;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td:nth-child(1) {
  width: 96px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td:nth-child(2) {
  width: 290px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td:nth-child(3) {
  width: 220px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td:nth-child(4) {
  width: 220px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td:nth-child(5) {
  width: 120px;
}
.dashboard_table_idFactura {
  max-width: 244px;
}
.dashboard_table_idFactura h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.dashboard_table_idFactura p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7e8488;
}
.dashboard_table_scadent {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.dashboard_table_valoare {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1d1d1d;
}
.dashboard_table_status_achitat {
  display: flex;
  align-items: center;
}
.dashboard_table_status_achitat img {
  width: 24px;
}
.dashboard_table_status_achitat p {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #7e8488;
}
.dashboard_table_header {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}
.dashboard_table_header_title {
  display: flex;
}
.dashboard_table_header_dropdowns {
  display: flex;
  margin-right: auto;
  margin-left: 40.5px;
}
.dashboard_table_header_title h2 {
  color: #1d1d1d;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-header {
  background-color: white;
  border: 0;
  border-top: 1px solid #e8e0e0;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  padding: 0;
  padding-left: 39.5px;
  padding-right: 39.5px;
  padding-top: 33.81px;
  padding-bottom: 33px;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th {
  background-color: white;
  color: #7e8488;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding-top: 0;
  padding-left: 0;
  box-shadow: none;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td {
  padding: 0;
  padding-bottom: 40px;
  border: 0;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr:nth-child(1)
  > td {
  padding-top: 20px;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper {
  padding-left: 39.5px;
  padding-right: 39.5px;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-paginator.p-component.p-paginator-bottom {
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-bottom: 1px solid #e8e0e0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.modifica-date-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  overflow: scroll;
  padding-bottom: 4%;
  padding-top: 4%;
}

.modifica-date-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 50%;
  max-width: 60%;
}

.modifica-date-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.modifica-date-header h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  color: #1d1d1d;
  align-self: center;
}
.modifica-date-row-selector {
  display: flex;
  position: relative;
  margin-bottom: 24px;
}
.modifica-date-row-selector :nth-child(2) {
  margin-left: 40px;
}
.modifica-date-tab {
  cursor: pointer;
}
.modifica-date-tab p {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #7e8488;
}
.modifica-date-tab-selected {
  border-bottom: 4px solid #ee324c;
  z-index: 1;
}
.modifica-date-tab-selected p {
  color: #1d1d1d;
  margin-bottom: 16px;
}
.modifica-date-textInput-row {
  margin-bottom: 24px;
}
.modifica-date-textInput-row h2 {
  color: #7e8488;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.modifica-date-modal-textInput {
  width: 100%;
}
.modifica-date-modal-end-buttons {
  display: flex;
  justify-content: flex-end;
}
.modifica-date-modal-end-buttons :nth-child(1) {
  margin-right: 24px;
}

.modifica-date-modal-select label {
  color: #7e8488;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.modifica-date-modal-react-select__indicators.css-1hb7zxy-IndicatorsContainer
  > span {
  display: none;
}
.modifica-date-modal-react-select__control {
  border-radius: 10px !important;
  border: 1px solid #e8e0e0 !important;
}
.modifica-date-modal-react-select__placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.modifica-date-modal-react-select__single-value {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.modifica-date-three-inputs {
  display: flex;
}
.modifica-date-litte-row:not(:last-child) {
  margin-right: 24px;
}

.modifica-date-two-inputs {
  display: flex;
  width: 65%;
}

.modifica-date-one-inputs {
  display: flex;
  width: 33.333%;
}

.modifica-date-one-inputs > div {
  margin-right: 15px;
}

.modifica-date-litte-row:not(:last-child) {
  margin-right: 24px;
}

.modifica-date-modal-textarea {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e8e0e0;
  background: #fff;
  padding: 9px 16px;
}

.locuriDeConsum-table-imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f4ff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.locuriDeConsum-table-imageContainer img {
  width: 16px;
  height: auto;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.locuriDeConsum-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr:nth-child(1)
  > td {
  padding-top: 21.63px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.locuriDeConsum-table
  > div.p-datatable-wrapper
  > table
  > thead {
  display: none;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.locuriDeConsum-table
  > div.p-datatable-header {
  background: white;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 0;
  padding-left: 39.5px;
  padding-right: 39.5px;
  padding-top: 26.31px;
  padding-bottom: 16.88px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable-wrapper {
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  padding-left: 39.5px;
  padding-right: 39.5px;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.locuriDeConsum-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td {
  border: 0;
  padding: 0;
  padding-bottom: 33.75px;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-paginator.p-component.p-paginator-bottom {
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-bottom: 1px solid #e8e0e0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.locuriDeConsum-table-header-two-items {
  display: flex;
  align-items: center;
}
.locuriDeConsum-table-select__control {
  border-radius: 10px !important;
  border: 1px solid #e8e0e0 !important;
  min-width: 130px;
  margin-left: 32px;
}

.locuriDeConsum-table-react-select__indicators.css-1u9des2-IndicatorsContainer
  > span {
  display: none;
}
.locuriDeConsum-table-export-button {
  padding: 3px 8px;
}
.locuriDeConsum-table-export-button-icon {
  display: flex;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.locuriDeConsum-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td:nth-child(4) {
  text-align: right;
}

.locuriDeConsum-table-locatie p {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  max-width: 225px;
}
.locuriDeConsum-table-locatie-tipCumparator {
  color: #7e8488;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.locuriDeConsum-table-locatieCompleta {
  color: #7e8488;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  max-width: 207px;
}
.locuriDeConsum_table_header h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}

.locDeConsumContainer-header {
  display: flex;
  align-items: center;
  margin-top: 25.19px;
  margin-bottom: 40.19px;
}
.locDeConsumHeader-header {
  margin-left: 24px;
}
.locDeConsumHeader-header p {
  color: #7e8488;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.locDeConsumHeader-header h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.locDeConsumInfo-adresa-servicii-container {
  margin-top: 38px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}
.locDeConsumInfo-adresa {
  padding: 26px 39px;
  display: flex;
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  flex-direction: column;
  flex: 1;
}
.locDeConsumInfo-adresa h2 {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}
.locDeConsumInfo-adresa p {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
  color: #7e8488;
}
.locDeConsumInfoContent-adresa-button {
  display: flex;
  max-width: 184px;
  padding: 10px 0px;
  justify-content: center;
}
.locDeConsumInfoContent-conventieConsum-wrapper {
  display: flex;
  max-width: 250px;

  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
}

.locDeConsumInfo-serviciiActive {
  padding: 26px 39px;
  display: flex;
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  justify-content: space-between;
  flex: 1;
}

.locDeConsumInfo-verificari {
  padding: 26px 39px;
  display: flex;
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  justify-content: space-between;
  flex: 1;
  margin-right: 41px;
}

.locDeConsumInfo-revizii {
  padding: 26px 39px;
  display: flex;
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  justify-content: space-between;
  flex: 1;
}
.locDeConsumInfo-conventieConsum {
  padding: 26px 39px;
  display: flex;
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  justify-content: space-between;
  flex: 1;
}

.locDeConsumInfo-conventieConsum-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.locDeConsumInfo-serviciiActive-text h2 {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}

.locDeConsumInfo-conventieConsum-text h2 {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}
.locDeConsumInfo-serviciiActive-text p {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  color: #7e8488;
}
.gaz-icon-locDeConsum-container {
  display: flex;
  background: #f2f4ff;
  height: 65px;
  width: 65px;
  align-items: center;
  justify-content: center;
  align-self: baseline;
  border-radius: 50%;
}
.gaz-icon-locDeConsum-container img {
  height: 41px;
}

.locDeConsumInfo-ConsumOrar {
  margin-top: 38.27px;
  margin-bottom: 32px;
  padding: 26px 39px;
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  display: flex;
  flex-direction: column;
}

.locDeConsumInfo-ConsumOrar h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}

.locDeConsumInfo-ConsumOrar-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.locDeConsumInfo-ConsumOrar-selectors {
  display: flex;
  flex-wrap: wrap;
}

.procesVerbal_table_header h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}
.procesVerbal-table-row-export {
  display: flex;
  justify-content: space-between;
}
.procesVerbal-table-selectors {
  display: flex;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.procesVerbal-table
  > div.p-datatable-header {
  background: white;
  border: 0;
  border-top: 1px solid #e8e0e0;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 0;
  padding-left: 39.5px;
  padding-right: 39.5px;
  padding-top: 26.31px;
  padding-bottom: 21.53px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.procesVerbal-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th {
  background-color: white;
  color: #7e8488;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.procesVerbal-table
  > div.p-datatable-wrapper {
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  padding-left: 39.5px;
  padding-right: 39.5px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.procesVerbal-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td {
  border: 0;
  padding: 0;
  padding-bottom: 32px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.procesVerbal-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr:nth-child(1)
  > td {
  padding-top: 24.89px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.procesVerbal-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td:nth-child(3) {
  text-align: right;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.procesVerbal-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th:nth-child(3)
  > div {
  justify-content: flex-end;
}

.locuriDeConsum-table-detalii :nth-child(1) {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #1d1d1d;
}
.locuriDeConsum-table-detalii :nth-child(2) {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #7e8488;
}

.suport-card {
  display: flex;
  padding: 29.25px 38px;
  border-radius: 24px;
  border: 1px solid #e8e0e0;
}
.suport-card-scris {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 24px;
}
.suport-card-scris h2 {
  /* margin-top: 18.5px; */
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.suport-card-scris p,
.suport-card-scris a {
  color: #7e8488;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
  max-width: 235px;
}
.suport-card-img {
  align-self: flex-start;
}
.suport-card-row {
  display: flex;
  flex-wrap: wrap;
}
.suport-card:not(:last-child) {
  margin-right: 24px;
}

.buton-call-suport {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 10px 18px;
}

.rapoarte_header_row {
  position: relative;
  margin-bottom: 33px;
  margin-top: 32.5px;
}

.rapoarte_header_selectors {
  display: flex;
  flex-direction: row;
}
.rapoarte_header_selectors :nth-child(2) {
  margin-left: 40px;
}
.rapoarte_header_selectors :nth-child(3) {
  margin-left: 40px;
}

.rapoarte_header_option {
  cursor: pointer;
}
.rapoarte_header_option p {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #7e8488;
}
.rapoarte_header_option-selected p {
  color: #1d1d1d;
  margin-bottom: 24.61px;
}
.rapoarte_header_option-selected {
  border-bottom: 4px solid #ee324c;
  z-index: 1;
}

.rapoarte-grafic-simplificat {
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  display: flex;
  margin-bottom: 32px;
}
.rapoarte-grafic-simplificat-title-row {
  display: flex;
  align-items: center;
  margin-top: 36.31px;
  margin-left: 39px;
}
.rapoarte-grafic-simplificat-title-row h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin-right: 16px;
}

.rapoarte-grafic-simplificat-title-row p {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #7e8488;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.rapoarte-grafic-simplificat
  > div.rapoarte-grafic-simplificat-chart-and-header
  > div.echarts-for-react.rapoarte-grafic-simplificat-chart
  > div {
  width: auto !important;
}
.echarts-for-react.rapoarte-grafic-simplificat-chart {
  min-height: 387px !important;
}
.rapoarte-grafic-simplificat-info {
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  padding: 20.31px 29.5px;
  display: flex;
  flex-direction: column;
}

.rapoarte-grafic-simplificat-info h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.rapoarte-grafic-simplificat-info p {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  margin-right: 8px;
}

.rapoarte-grafic-simplificat-info-container {
  display: flex;
  flex-direction: column;
  margin-top: 14.5px;
  margin-bottom: 14.5px;
  margin-right: 12.5px;
}
.rapoarte-grafic-simplificat-info-container div:not(:last-child) {
  margin-bottom: 17px;
}

.rapoarte-grafic-simplificat-chart-and-header {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.suport-table-row-selectors {
  display: flex;
  flex-wrap: wrap;
}
.facturileMele-table-row-selectors {
  display: flex;
  flex-wrap: wrap;
}
.status-table-tipTichet p,
.status-table-id p,
.status-table-tipFurnizare p,
.status-table-subiect p,
.status-table-locDeConsum p,
.status-table-dataProblemei p,
.status-table-status p {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #1d1d1d;
  text-align: center;
}
.status-table-subiect p {
  max-width: 195px;
}
.status-table-tipTichet {
  width: 80px;
  text-align: left !important;
}
.status-table-locDeConsum p {
  max-width: 233px;
}
.status-table-tipFurnizare {
  display: flex;
  align-items: center;
}
.status-table-tipFurnizare-icon-container {
  background-color: #f2f4ff;
  border-radius: 50%;
  width: auto;
  display: flex;
  padding: 4px;
  align-items: center;
  margin-right: 8px;
}
.status-table-tipFurnizare-icon-container img {
  width: 13px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table {
  margin-top: 32px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th {
  color: #7e8488;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  background-color: white;
  padding-top: 0;
  padding-left: 0;
  box-shadow: none;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td {
  padding: 0;
  padding-bottom: 31px;
  border: 0;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr:nth-child(1)
  > td {
  padding-top: 37px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
  > div.p-datatable-header {
  background-color: white;
}
.suport_table_header h2 {
  margin-bottom: 28.39px;
  font-size: 24px;
  color: #1d1d1d;
  font-weight: 400;
  line-height: normal;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
  > div.p-datatable-header {
  border: 0;
  border-top: 1px solid #e8e0e0;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 0;
  padding-top: 25.87px;
  padding-left: 39.5px;
  padding-right: 39.5px;
  padding-bottom: 33px;
}

.gaz-background {
  background-color: #f2f4ff;
}
.curent-background {
  background-color: #fff7ec;
}

.status-table-status {
  display: flex;
  align-items: center;
}
.status-table-status p {
  margin-left: 8px;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
  > div.p-datatable-wrapper {
  padding-left: 38.5px;
  padding-right: 38.5px;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
  > div.p-datatable-wrapper {
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
  > div.p-paginator.p-component.p-paginator-bottom {
  border: 0;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-bottom: 1px solid #e8e0e0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.facturi_si_plati-selector-row {
  position: relative;
}
.facturi_si_plati-selectors {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e8e0e0;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding-top: 34px;
  padding-left: 39.5px;
}
.facturi_si_plati-selectors :nth-child(2) {
  margin-left: 40px;
}
.facturi_si_plati-selectors :nth-child(3) {
  margin-left: 40px;
}

.facturi_si_plati-option {
  cursor: pointer;
}
.facturi_si_plati-option p {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #7e8488;
}
.facturi_si_plati-option-selected p {
  color: #1d1d1d;
  margin-bottom: 24.61px;
}
.facturi_si_plati-option-selected {
  border-bottom: 4px solid #ee324c;
  z-index: 1;
}
.facturileMele-table-tipFurnizare img {
  width: 40px;
}

.buton_facturileMele-export {
  margin-left: auto;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td {
  border: 0px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.facturileMele-table
  > div.p-datatable-header {
  background-color: white;
  padding-top: 32px;
  padding-left: 39.5px;
  padding-right: 38.5px;
  padding-bottom: 20.8px;
  border: 0;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.facturileMele-table
  > div.p-datatable-wrapper {
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  padding-left: 23.5px;
  padding-right: 23.5px;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.facturileMele-table
  > div.p-paginator.p-component.p-paginator-bottom {
  border: 0;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-bottom: 1px solid #e8e0e0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.facturileMele_table_header {
  display: flex;
  flex-wrap: wrap;
}
.facturileMele_table_header p {
  align-self: center;
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-right: 32px;
}

.facturileMele-table-locDeConsum p {
  max-width: 244px;
  color: #7e8488;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.facturileMele_table_header_checkbox {
  display: none;
}
.facturileMele-table-serie p,
.facturileMele-table-scadent p,
.facturileMele-table-valoare p {
  color: #1d1d1d;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-serie-mobile {
  display: none;
}
.facturileMele-table-serie p:hover {
  text-decoration: underline;
  color: #52c2b5;
}
.facturileMele-table-locDeConsum-mobile {
  max-width: 244px;
  color: #7e8488 !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  line-height: normal;
}
.facturileMele_table_status_achitat {
  display: flex;
  align-items: center;
}
.facturileMele_table_status_achitat img {
  width: 24px;
}
.facturileMele_table_status_achitat p {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #7e8488;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th {
  background-color: white;
  color: #7e8488;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding-top: 9.2px;
  box-shadow: none;
}

.istoricPlati-table-id p,
.istoricPlati-table-numarTranzactie p,
.istoricPlati-table-data p,
.istoricPlati-table-totalPlata p,
.istoricPlati-table-facturiAchitate p {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.istoricPlati-table-numarTranzactie p {
  max-width: 400px;
  word-break: break-all;
}
.istoricPlati-table-facturiAchitate p {
  max-width: 380px;
  word-break: break-all;
}

.istoricPlati_table_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.istoricPlati_table_header p {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
  > div.p-datatable-header {
  padding: 0;
  border: 0;
  background-color: white;
  padding-top: 32px;
  padding-left: 39.5px;
  padding-right: 38.5px;
  padding-bottom: 20.8px;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th {
  color: #7e8488;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  background-color: white;
  padding-top: 0px;
  box-shadow: none;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
  > div.p-datatable-wrapper {
  padding-left: 27.5px;
  padding-right: 27.5px;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
  > div.p-paginator.p-component.p-paginator-bottom {
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-bottom: 1px solid #e8e0e0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
  > div.p-datatable-header {
  background-color: white;
  padding-top: 32px;
  padding-left: 39.5px;
  padding-right: 38.5px;
  padding-bottom: 37.5px;
  border: 0;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th {
  color: #7e8488;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  background-color: white;
  padding-top: 0px;
  box-shadow: none;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
  > div.p-datatable-wrapper {
  padding-left: 27.5px;
  padding-right: 27.5px;
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td {
  border: 0;
  width: 33%;
}
#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
  > div.p-paginator.p-component.p-paginator-bottom {
  border-left: 1px solid #e8e0e0;
  border-right: 1px solid #e8e0e0;
  border-bottom: 1px solid #e8e0e0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.transmitereFactura_table_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transmitereFactura_table_header p {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.transmitereFactura-table-tipFurnizare img {
  width: 40px;
}

.transmitereFactura-table-transmitereFactura {
  display: flex;
}

.transmitereFactura-table-buttons {
  display: flex;
  background-color: #e8e0e0;
  padding: 2px 2px;
  border-radius: 8.91px;
}

.transmitereFactura-table-button p {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.08px;
  padding: 6px 12px;
  min-width: 103px;
}

.transmitereFactura-table-button-selected {
  background-color: #ffffff;
  color: #1d1d1d;
  border-radius: 8.91px;
}

.rapoarte-grafic-detaliat-mare {
  border: 1px solid #e8e0e0;
  border-radius: 24px;
  margin-bottom: 32px;
}

.rapoarte-grafic-detaliat-mare-header {
  display: flex;
  align-items: center;
  padding-left: 36.1px;
  padding-top: 39.5px;
}
.rapoarte-grafic-detaliat-mare-header h2 {
  color: #1d1d1d;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
}
.rapoarte-grafic-detaliat-mare-header p {
  color: #7e8488;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-left: 16px;
}

.rapoarte-grafic-detaliat-dublu {
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  margin-bottom: 32px;
}
.rapoarte-grafic-detaliat-dublu h2 {
  color: #1d1d1d;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin-top: 36.31px;
  margin-left: 39.5px;
  margin-bottom: 32px;
}
.rapoarte-grafic-detaliat-solo {
  width: 50%;
}
.rapoarte-grafic-detaliat-solo h2 {
  margin: 0;
  margin-left: 39.5px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.rapoarte-grafic-detaliat-dublu-row {
  display: flex;
}

.rapoarte-grafic-detaliat-mare-curbaConsum {
  border-radius: 24px;
  border: 1px solid #e8e0e0;
}

.rapoarte-grafic-detaliat-mare-curbaConsum h2 {
  color: #1d1d1d;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin-top: 36.31px;
  margin-left: 39.5px;
  margin-bottom: 24px;
}

.rapoarte-grafic-detaliat-mare-header-curbaConsum-row {
  margin-left: 39.5px;
  display: flex;
}

.deschide-tichet-modal-datepicker-dataProblemei-wrapper {
  width: 100%;
}

.deschide-tichet-modal-datepicker-dataProblemei-wrapper input {
  width: 100%;
  height: 38px;
  border: 1px solid #e8e0e0;
  border-radius: 10px;
  padding-left: 8px;
}

.deschide-tichet-modal-datepicker-dataProblemei-wrapper input::placeholder {
  font-size: 14px;
}

.error-datepicker-wrapper input {
  border: 1px solid #ff1212 !important;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th
  > div
  > span.p-sortable-column-icon
  > svg {
  width: 14px;
  display: flex;
  align-self: center;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th
  > div
  > span.p-sortable-column-icon
  > svg {
  width: 14px;
  display: flex;
  align-self: center;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th
  > div
  > span.p-sortable-column-icon
  > svg {
  width: 14px;
  display: flex;
  align-self: center;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th
  > div
  > span.p-sortable-column-icon
  > svg {
  width: 14px;
  display: flex;
  align-self: center;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.autocitire-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th
  > div
  > span.p-sortable-column-icon
  > svg {
  width: 14px;
  display: flex;
  align-self: center;
}

#root
  > div.page-layout
  > div.page-layout-content-right
  > div.container-page-content
  > div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th
  > div
  > span.p-sortable-column-icon
  > svg {
  width: 14px;
  display: flex;
  align-self: center;
}

.facturiMele-dialog span {
  font-weight: 700;
}

.facturiMele-dialog div.content {
  margin-top: 16px;
}

.facturiMele-dialog div.second {
  margin-top: 16px;
}

/*.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {*/
/*   background: #52c2b5;*/
/*   color: #ffffff;*/
/*   border-color: #52c2b5;*/
/*}*/

.p-splitbutton .p-splitbutton-defaultbutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
  padding: 10px !important;
  font-family: "DM Sans" !important;
  /*height: 38px !important;*/
}

.p-component {
  font-family: "DM Sans" !important;
}

.p-button {
  background: #52c2b5 !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 32px;
  height: 38px;
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: 1px solid #52c2b5 !important;
  cursor: pointer;
  height: auto;
  font-family: "DM Sans" !important;
}

.p-menu .p-menuitem-link {
  border-radius: 10px !important;
  padding: 10px 32px;
  color: #ffffff;
  background: #52c2b5 !important;
  transition: box-shadow 0.2s;
  -webkit-user-select: none;
  user-select: none;
  font-family: "DM Sans" !important;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: #ffffff !important;
  font-family: "DM Sans" !important;
}

.facturileMele-table .card {
  border: none !important;
}

.p-button.p-button-text {
  color: #fff !important;
  font-family: "DM Sans" !important;
}

.p-inputtext {
  padding: 8px 16px !important;
  font-size: 14px !important;
  font-family: "DM Sans" !important;
}

a:hover {
  color: #52c2b5 !important;
}

.datatable-content-div {
  display: flex;
  flex-wrap: wrap;
}

.readonly {
  background-color: #e9ecef;
}
.sold-container-elements {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.conventie-consum-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  padding: 26px 39px;
  display: flex;
  border-radius: 24px;
  border: 1px solid #e8e0e0;
  justify-content: space-between;
  flex: 1;
}

.conventie-consum-edit-buttons-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  gap: 10px;
  max-height: 38px;
}
.conventie-consum-edit-button {
  width: 150px;
  align-self: flex-end;
}
.conventie-consum-cancel-button {
  background-color: #f02d4b;
  width: 250px;
  width: 150px;
}

.conventie-consum-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-bottom: 40px;
  gap: 20px;
}

.conventie-consum-switch-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin: 12px 0;
  border: 1px solid #e8e0e0;
  padding: 10px;
  border-radius: 24px;
  max-width: 420px;
  align-items: flex-start;
}
.conventie-consum-submit-button {
  width: 150px;
}
.conventie-consum-row {
  display: flex;
  flex-direction: row;
  justify-content: around;
  align-items: center;
  margin: 8px 0;
  border-bottom: 1px solid #e8e0e0;
  padding-bottom: 10px;
}
.conventie-consum-row:last-child {
  border-bottom: none;
}

.conventie-consum-row-label {
  width: 250px;
}

@media screen and (max-width: 560px) {
  .conventie-consum-row-label {
    width: 150px;
  }
  .conventie-consum-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    padding: 20px 10px;
    display: flex;
    border-radius: 24px;
    border: 1px solid #e8e0e0;
    justify-content: space-between;
    flex: 1;
  }

  .conventie-consum-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 5px;

    padding-bottom: 20px;
  }
  .conventie-consum-edit-buttons-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: flex-end;
    gap: 10px;
    max-height: 68px;
    border-top: 1px solid #e8e0e0;
    padding-top: 20px;
    width: 100%;
  }

  .conventie-consum-switch-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin: 12px;
    border: 1px solid #e8e0e0;
    padding: 10px;
    border-radius: 24px;
    gap: 5px;
    align-items: flex-start;
  }
  .conventie-consum-switch-container > div > p {
    padding: 0px;
    margin: 0px;
  }
  .conventie-consum-table {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.conventie-consum-row-text {
  margin-right: 8px;
  font-size: 18px;
  text-transform: uppercase;
}

.input-consumption {
  border: 1px solid #e2e8f0;
  margin-top: 8px;
  width: 150px;
  max-width: 150px;
  border-radius: 10px;
  height: 36px;
  padding-left: 16px;
  font-size: 19px;
  color: #1d1d1d;
  background-color: #fff;

  padding-right: 0;
  padding-left: 7px;
  text-align: right;
  transition:
    background-color 0.3s ease,
    color 0.3s ease; /* Add transition effect */
}

.input-consumption:disabled {
  background-color: #f2f4ff;
  color: #7e8488;
}

.divider {
  border-bottom: 1px solid #e2e8f0;
}
.switch-container {
  display: flex;
  justify-content: center;

  align-items: center;
}
.switch-container p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.27px;
  line-height: 42px;
  text-decoration: line-through;
}

.switch {
  width: 50px;
  height: 30px;
  background: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
}
.switch--on {
  background: #5bb4a4;
}
.switch--on-text {
  color: #f02d4b;
  font-weight: bold !important;
  font-size: 15px !important;
  text-decoration: none !important;
  transition: all 0.3s;
}

.switch__inner {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

.switch--on .switch__inner {
  left: 22px;
}

@media screen and (min-width: 1350px) {
  .sold-container-elements {
    display: flex;

    flex-direction: column-reverse;
  }
}

.modal-declaratie-title {
  color: #171619;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.38px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 25px;
}

.modal-declaratie-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  overflow: scroll;
  padding-bottom: 4%;
  padding-top: 4%;
}

.modal-declaratie-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 50%;
  max-width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.text-red {
  color: #f02d4b !important;
}
.text-gray {
  color: #5d5d5c !important;
}
.modal-declaratie-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  width: 100%;
}

.signCanvas {
  border: 1.6px solid #ddd3c9;
  border-radius: 8px;
  text-align: center;
  width: 500px;
  height: 250px;
}
@media screen and (max-width: 920px) {
  .signCanvas {
    border: 1.6px solid #ddd3c9;
    border-radius: 8px;
    text-align: center;
    width: 250px;
    height: 250px;
  }
  .modal-declaratie-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    min-width: 90%;
    max-width: 90%;

    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.signature-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  border: 1.6px solid #2db7a4;
}
.signature-buttons-container {
  text-align: right;
  margin-top: 15px;
}

.signature-button {
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.34px;
  line-height: 24px;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.notificari-legale-number p {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  text-align: left;
}
.notificari-legale-number a {
  color: #00a693 !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}
.notificari-legale-date p {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #1d1d1d;
}
/* @media screen and (max-width: 320px) {
  .sold-container-elements{
  display: flex;

  flex-direction: column-reverse;
  } */
/* } */
