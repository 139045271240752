@media screen and (max-width: 1350px) {
  .dashboard-3rd-container {
    flex: 1 1;
    align-items: normal;
    order: 1;
  }
  .dashboard-sold-container {
    margin-bottom: 24px;
    flex-direction: row;
    max-width: none;
    width: 100%;
    order: 1;
  }
  .dashboard-locuri-consum {
    max-width: none;
  }
  .sold-container-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
  }

  /* @media screen and (max-width: 1349px) {
    .sold-container-elements{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }  */

  #dashboard-red-card-sold-wrapper{
    flex: 2;
  }
#dashboard-red-card-image {
    margin-bottom: 0;
    align-self: baseline;
    margin-right: 16.75px;
  }
  #dashboard-red-card-image-wrapper {
    flex: 1 1 0%; display: flex; place-content: center;
  }
  .dashboard-sold-container div:nth-of-type(3) {
    max-width: none;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .rapoarte-grafic-detaliat-dublu-row {
    flex-direction: column;
  }
  .rapoarte-grafic-detaliat-solo {
    width: 100%;
  }

  .dashboard-content-div {
    flex-direction: column;
  }
  .grafic-facturi {
    width: 100%;
    order: 2;
    margin-bottom: 0;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table {
    width: 100%;
  }
  .dashboard-locuri-consum {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .rapoarte-grafic-simplificat {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .rapoarte-grafic-simplificat-info-container {
    margin-left: 12.5px;
  }
  .echarts-for-react.rapoarte-grafic-simplificat-chart {
    min-height: auto !important;
  }
  .suport-card-row {
    flex-direction: column;
  }
  .suport-card:not(:last-child) {
    margin-bottom: 24px;
    margin-right: 0;
  }
}

@media screen and (max-width: 1135px) {
  .facturileMele_table_header p {
    order: 1;
    width: 50%;
    margin-right: 0;
  }
  .buton_facturileMele-export {
    order: 2;
  }
  .facturileMele-table-row-selectors {
    margin-top: 16px;
    order: 3;
    width: 100%;
    flex-direction: column;
  }
  .facturileMele-table-tipFurnizare__control {
    margin-right: 0;
    margin-bottom: 16px;
    max-width: none;
  }
  .facturileMele-table-locDeConsum__control {
    margin-right: 0;
    margin-bottom: 16px;
    max-width: none;
  }
  .facturileMele-table-status__control {
    margin-right: 0;
    margin-bottom: 16px;
    max-width: none;
  }
  /* AI GRIJA LA ASTA */
  .suport-table-row-selectors {
    margin-top: 16px;
    order: 3;
    width: 100%;
    flex-direction: column;
  }
}

@media screen and (max-width: 1010px) {
  .dashboard_table_header {
    flex-wrap: wrap;
  }
  .dashboard_table_header_title {
    display: flex;
    order: 1;
    width: 50%;
  }
  .dashboard_table_header_button {
    order: 2;
    width: 50%;
    justify-content: flex-end;
    display: flex;
  }
  .dashboard_table_header_dropdowns {
    margin: 0;
    flex-direction: column;
    order: 3;
    width: 100%;
    margin-top: 16px;
  }
  .dashboard_table_header_second_select {
    margin-top: 16px;
  }
  .dashboard-table-facturi-button {
    font-size: 12px;
    line-height: 16px;
    padding: 10px 16px;
  }
  .dashboard_table_header-months-select__control {
    margin-right: 0;
    margin-bottom: 16px;
    max-width: none;
  }
  .dashboard_table_header-tipFurnizare__control {
    max-width: none;
  }
}

@media screen and (max-width: 768px) {
  /*#root > div.page-layout > div.page-layout-content-right > div.container-page-content > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table > div.p-datatable-wrapper > table > tbody > tr{*/
  /*  border-bottom: 1px solid #e8e0e0;*/
  /*}*/
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    td {
    border: 0;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    td:last-of-type {
    width: 100%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    td:nth-child(2) {
    padding-bottom: 0;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    td:first-of-type {
    position: absolute;
    left: 0;
    border: 0;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr {
    display: flex;
    flex-wrap: wrap;
    padding-left: 35px;
  }
  .suport-table .css-13cymwt-control {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
  }
  .suport-table thead tr,
  .dashboard-table thead tr {
    display: none;
  }
  .autocitire-table-autocitiri-export {
    display: flex;
    flex-wrap: wrap;
  }
  .suport-table-row-selectors {
    display: flex;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.locuriDeConsum-table
    > div.p-datatable-header,
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.locuriDeConsum-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td {
    padding-bottom: 20px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2)::before {
    content: "Serie factura";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(4)::before {
    content: "Scadent";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5)::before {
    content: "Valoare";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td
    .facturileMele-table-serie-mobile {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr {
    display: flex;
    flex-wrap: wrap;
    padding-left: 35px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    position: absolute;
    left: 0;
    padding: 16px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(4),
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5) {
    width: 50%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:last-of-type {
    width: 100%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    td:nth-child(3) {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
  div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    .facturileMele-table-serie-mobile {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }

  div.p-datatable.p-component.p-datatable-responsive-scroll.locuriDeConsum-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr {
    display: flex;
    flex-wrap: wrap;
    padding-left: 35px;
  }
  div.p-datatable.p-component.p-datatable-responsive-scroll.locuriDeConsum-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:last-of-type {
    width: 100%;
    text-align: left !important;
    padding-top: 0 !important;
  }
  div.p-datatable.p-component.p-datatable-responsive-scroll.locuriDeConsum-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3) {
    padding-top: 0 !important;
    width: 100%;
  }

  div.p-datatable.p-component.p-datatable-responsive-scroll.locuriDeConsum-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    position: absolute;
    left: 15px;
    padding: 16px;
  }

  .suport-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1)::before {
    content: "Tip tichet";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .suport-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2)::before {
    content: "Id";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .suport-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3)::before {
    content: "Tip furnizare";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .suport-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(4)::before {
    content: "Subiect";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .suport-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5)::before {
    content: "Loc de consum";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .suport-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(6)::before {
    content: "Data problemei";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .suport-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(7)::before {
    content: "Status";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .suport-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td:nth-child(8)::before {
  content: "Rezoluție";
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #7e8488;
}
  div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    td {
    width: 50%;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  div.p-datatable.p-component.p-datatable-responsive-scroll.suport-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e8e0e0;
  }
  .suport-table .p-datatable-wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .utilizatori-content .utilizatori-card .utilizatori-btns {
    margin-top: 25px;
  }
  .utilizatori-div .utilizatori-header .utilizatori-btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  .utilizatori-content .utilizatori-card {
    flex-wrap: wrap;
  }
  .custom-submitButton.buton-bg-alb-red.ml-4 {
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .my-account-div .my-account-buttons {
    flex-wrap: wrap;
    gap: 10px;
  }
  .contractele-mele-card {
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto !important;
  }
  .contractele-mele-div .contractele-mele-header {
    padding-left: 22.5px;
    padding-right: 22.5px;
  }
  .contractele-mele-div {
    padding-left: 0;
    padding-right: 0;
  }
  .my-account-buttons :nth-child(2) {
    margin-left: 0;
    margin-top: 10px;
  }
  .contractele-mele-card .gaz-curent-icon-contracte,
  .contractele-mele-card
    .gaz-curent-icon-contracte.gaz-curent-icon-electricitate-contracte {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
  }
  .header-conturi-asociate {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .modifica-date-container {
    padding: 0;
  }
  .modifica-date-content {
    min-height: 100%;
    max-width: 100%;
    min-width: 100%;
    border-radius: 0;
  }
  .login-background {
    display: none;
  }
  .login-background-mobile-top {
    display: block;
    left: 5%;
    top: 10%;
  }
  .login-background-mobile-bottom {
    display: block;
    right: 5%;
    bottom: 10%;
  }
  .dashboard-grafic-container {
    order: 3;
    margin-top: 24px;
  }
  .left-menu {
    display: none;
  }
  .footer {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    height: 83px;
    background: #ffffff;
    box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.3);
  }
  .header-edit-profile-content div:nth-of-type(2) {
    display: inherit;
  }
  .page-layout-header {
    margin-left: 16px;
    margin-right: 16px;
  }
  .container-page-content {
    flex: 1;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 52px;
  }
  .welcome-container {
    margin-bottom: 24px;
  }
  .grafic-facturi-content {
    flex-direction: column;
  }
  .grafic-facturi-selectors {
    flex-direction: column;
    padding-right: 22px;
    padding-left: 21px;
  }
  .dashboard-grafic-months__control {
    margin-right: 0;
    margin-bottom: 16px;
    max-width: none;
  }
  .dashboard-grafic-tipFurnizare__control {
    max-width: none;
  }

  .grafic-facturi-content h2 {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 26px;
    padding-left: 21px;
  }
  .content-contul-meu-row {
    flex-direction: column;
  }
  .my-account-div {
    width: 100%;
  }
  .panou-recomandari-div {
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }
  .contractele-mele-container {
    flex-direction: column;
  }
  .autocitire-cards-container .autocitire-card:nth-child(2) {
    margin-left: 8px;
  }
  .autocitire-card {
    flex-direction: column;
    padding-left: 25.5px;
    padding-right: 25.5px;
    padding-top: 18.8px;
    padding-bottom: 18px;
    align-items: center;
  }
  .autocitire-card-image-container {
    width: 31px;
    height: 31px;
  }
  .autocitire-card-image-container img {
    width: 22px;
  }
  .autocitire-card-content {
    margin-top: 5.92px;
  }
  .autocitire-card-content h2 {
    font-size: 14px;
    line-height: 18px;
  }
  .autocitire-card-content p {
    margin-bottom: 7px;
    font-size: 12px;
    line-height: 16px;
  }
  .autocitire-card-button {
    font-size: 12px;
    line-height: 16px;
    padding: 10px 16px;
    min-width: 101px;
  }
  .autocitire_table_option p {
    font-size: 14px;
    line-height: 18px;
  }
  .facturi_si_plati-selectors {
    padding-top: 23.94px;
    padding-left: 20.26px;
  }
  .facturi_si_plati-option p {
    font-size: 14px;
    line-height: 18px;
  }
  .rapoarte_header_option p {
    font-size: 14px;
    line-height: 18px;
  }
  .autocitire-table-autocitiri-export :nth-child(1) {
    font-size: 12px;
    line-height: 16px;
  }
  .autocitire-table-export-button {
    font-size: 12px;
    line-height: 16px;
    padding: 10px 16px;
  }
  .dashboard_table_header_title h2 {
    font-size: 20px;
    line-height: normal;
  }
  .dashboard_table_idFactura h2 {
    font-size: 14px;
    line-height: normal;
  }
  .dashboard_table_idFactura p {
    font-size: 11px;
    line-height: normal;
  }
  .dashboard_table_scadent {
    font-size: 14px;
    line-height: normal;
  }
  .dashboard_table_valoare {
    font-size: 14px;
    line-height: normal;
  }
  .dashboard_table_status_buton {
    width: 69%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-header {
    padding-top: 18.21px;
    padding-left: 20.6px;
    padding-right: 22.4px;
    padding-bottom: 24px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper {
    padding-left: 20.6px;
    padding-right: 20.6px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr:nth-child(1)
    > td {
    padding-top: 0;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td {
    padding-bottom: 16px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    width: 10%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    width: 90%;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(4)::before {
    content: "Scadent";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5)::before {
    content: "Valoare";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.autocitire-table
    > div.p-datatable-wrapper {
    padding-left: 21.33px;
    padding-right: 46.67px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-header {
    padding-right: 21.33px;
    padding-left: 21.33px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > thead
    > tr {
    display: none;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td {
    padding: 0;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    order: 1;
    width: 11%;
  }
  .autocitire_table_loc_de_consum {
    font-weight: 500;
    color: #1d1d1d;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3) {
    order: 2;
    width: 89%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3)::before {
    content: "Loc de consum";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    order: 3;
    margin-left: 11%;
    width: 44.5%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2)::before {
    content: "Data";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .autocitire_table_data {
    font-size: 14px;
    line-height: 18px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(4) {
    order: 4;
    width: 44.5%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(4)::before {
    content: "Consum total";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .autocitire_table_consum_total {
    font-size: 14px;
    line-height: 18px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5) {
    order: 5;
    margin-left: 11%;
    width: 44.5%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5)::before {
    content: "Autocitiri trimise";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .autocitire_table_autocitiri_trimise {
    font-size: 14px;
    line-height: 18px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(6) {
    order: 6;
    width: 44.5%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.autocitire-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(6)::before {
    content: "Status";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  .autocitire_table_status_div p {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .autocitire_table_status_icon {
    margin: 0;
  }
  .autocitire_table_status_icon path {
    fill: #1d1d1d;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > thead
    > tr {
    display: none;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
  .facturileMele_table_header_checkbox {
    order: 4;
    display: flex;
    width: 100%;
    margin-bottom: 16px;
  }
  .facturileMele_table_header_checkbox p {
    margin-left: 8px;
    color: #7e8488;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }
  .facturileMele_table_header {
    border-bottom: 1px solid #e8e0e0;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > thead {
    display: none;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td {
    border: 0;
    padding: 0;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-header {
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 23px;
    padding-bottom: 16px;
    flex-wrap: wrap;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper {
    padding-left: 20px;
    padding-right: 23px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper {
    padding-left: 9px;
    padding-right: 9px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > thead
    > tr {
    display: none;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e8e0e0;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td {
    border: 0;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1)::before {
    content: "Numar tranzactie";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2)::before {
    content: "Data";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3)::before {
    content: "Suma";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(4)::before {
    content: "Facturi achitate";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5)::before {
    content: "Facturi";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    width: 100%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    width: 50%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.istoricPlati-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5) {
    width: 100%;
  }
  .istoricPlati-table-facturiAchitate p {
    max-width: none;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24.87px;
    padding-bottom: 21.19px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > thead {
    display: none;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td {
    width: auto;
    padding: 0;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2)::before {
    content: "Detalii contract";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3)::before {
    content: "Transmitere factura";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    width: 10%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    width: 90%;
    margin-bottom: 16px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3) {
    margin-left: 10%;
    width: 100%;
    margin-bottom: 32px;
  }

  .facturileMele-table-serie p,
  .facturileMele-table-scadent p,
  .facturileMele-table-valoare p {
    font-size: 14px;
  }
  .facturileMele-table-locDeConsum p {
    font-size: 11px;
  }

  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td.p-selection-column {
    width: 8%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    width: 10%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3) {
    width: 82%;
  }
  .facturileMele-table-locDeConsum {
    display: none;
  }
  .facturileMele-table-serie-mobile {
    display: flex;
    color: #1d1d1d;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(4)::before {
    content: "Serie factura";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(4) {
    margin-left: 18%;
    width: 82%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5) {
    margin-left: 18%;
    width: 41%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(5)::before {
    content: "Scadent";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(6) {
    width: 41%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(6)::before {
    content: "Valoare";
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #7e8488;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(7) {
    margin-left: 18%;
    width: 100%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td.p-selection-column {
    padding-bottom: 16px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-selectable.p-datatable-responsive-scroll.facturileMele-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 576px) {
  .procesVerbal-table-row-export {
    flex-direction: column;
  }
  .procesVerbal-table-selectors {
    flex-direction: column;
  }
  .procesVerbal-table-tip__control {
    margin-bottom: 9px;
    margin-right: 0px;
    max-width: none;
  }
  .procesVerbal-table-an__control {
    margin-bottom: 9px;
    max-width: none;
  }
  .locDeConsumInfo-adresa {
    flex: auto;
    margin: 0;
    margin-bottom: 24px;
  }
  .locDeConsumInfo-verificari {
    flex: auto;
    margin: 0;
    margin-bottom: 24px;
  }
  .locDeConsumContainer-header {
    order: 3;
  }
  .locDeConsumInfo-ConsumOrar-header {
    flex-direction: column;
  }
  .locDeConsumInfo-ConsumOrar-selectors {
    flex-direction: column;
  }
  .locDeConsum-consumOrar-month__control {
    margin-right: 0;
    margin-bottom: 9px;
    max-width: none;
  }
  .locDeConsum-consumOrar-tipFurnizare__control {
    margin-bottom: 9px;
    max-width: none;
  }
  .login-main-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 32.28px;
    padding-bottom: 32.28px;
    margin-top: -120px;
  }
  .login-background-mobile-top {
    top: 5%;
    left: 4%;
    width: 50%;
  }
  .login-background-mobile-bottom {
    right: 4%;
    bottom: 5%;
    width: 41%;
  }
  .icon-notification-profile-container {
    order: 2;
    flex: 0 0 50%;
    max-width: 50%;
    justify-content: end;
  }
  .welcome-container {
    margin-bottom: 24px;
    order: 3;
  }
  .pageName-and-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    order: 1;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .welcome-and-icons-container {
    flex-wrap: wrap;
    margin-top: 40px;
  }
  .one-notification-div {
    display: none;
  }
  .welcome-container p:nth-child(1) {
    font-size: 14px;
    line-height: 18px;
  }
  .welcome-container p:nth-child(2) {
    font-size: 18px;
    line-height: 23px;
  }
  .welcome-container p:nth-child(3) {
    font-size: 12px;
    line-height: 16px;
  }
  .dashboard-sold-container {
    padding-top: 15.5px;
    padding-left: 17.41px;
    padding-right: 17.41px;
    padding-bottom: 18.5px;
  }
  .dashboard-sold-container img {
    width: 100px;
  }
  .dashboard-sold-container div:nth-of-type(1) {
    font-size: 12px;
    line-height: 16px;
  }
  .dashboard-sold-container div:nth-of-type(2) {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.01em;
  }
  .dashboard-sold-container div:nth-of-type(3) {
    font-size: 12px;
    line-height: 16px;
  }
  .dashboard-plateste {
    margin-top: 11px;
  }
  .my-account-div-content {
    flex-direction: column;
  }
  .my-account-details {
    width: 100%;
    margin-bottom: 16px;
  }
  .transmitereFactura-table-tipFurnizare img {
    width: 30px;
    margin-top: 8px;
  }
  .dashboard_table_status_buton {
    width: 79%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    margin-left: 2%;
    width: 88%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3) {
    margin-left: 12%;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div
    > div.p-datatable.p-component.p-datatable-responsive-scroll.dashboard-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    width: 88%;
    padding-bottom: 0;
  }
  .dashboard_table_tip_icon {
    width: 30px;
  }
  .facturileMele-table-tipFurnizare img {
    width: 30px;
  }
  #root
    > div.page-layout
    > div.page-layout-content-right
    > div.container-page-content
    > div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr {
    display: flex;
    flex-wrap: wrap;
  }
  div.p-datatable.p-component.p-datatable-responsive-scroll.transmitereFactura-table
    .p-datatable-emptymessage {
    display: block !important;
  }
  .rapoarte_header-locDeConsum__control {
    min-width: auto;
  }
  .rapoarte_header-locDeConsum__menu {
    min-width: auto;
  }
}

@media screen and (max-width: 430px) {
  .autocitire_table_selectors :nth-child(2) {
    margin-left: 48px;
  }
  .autocitire_table_selectors :nth-child(3) {
    margin-left: 48px;
  }
  .autocitire_table_selectors :nth-child(1) {
    max-width: 73px;
  }
  .facturi_si_plati-selectors :nth-child(2) {
    margin-left: 48px;
  }
  .facturi_si_plati-selectors :nth-child(3) {
    margin-left: 48px;
  }
  .facturi_si_plati-selectors :nth-child(1) {
    max-width: 73px;
  }
  .rapoarte_header_selectors :nth-child(2) {
    margin-left: 48px;
  }
  .rapoarte_header_selectors :nth-child(3) {
    margin-left: 48px;
  }
  .rapoarte_header_selectors :nth-child(1) {
    max-width: 73px;
  }
  .autocitire_table_tip_icon {
    width: 28px;
  }
}
