/* FACTURI_SI_PLATI FACTURILE_MELE TABLE START */
.facturileMele-table-tipFurnizare__control {
  margin-right: 16px;
  min-width: 175px;
  max-width: 175px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.facturileMele-table-tipFurnizare__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-tipFurnizare__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-tipFurnizare__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-tipFurnizare__indicator-separator {
  background-color: transparent !important;
}

.facturileMele-table-locDeConsum__control {
  margin-right: 16px;
  min-width: 195px;
  max-width: 225px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.facturileMele-table-locDeConsum__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-locDeConsum__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-locDeConsum__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-locDeConsum__indicator-separator {
  background-color: transparent !important;
}

.facturileMele-table-status__control {
  margin-right: 16px;
  min-width: 136px;
  max-width: 136px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.facturileMele-table-status__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-status__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-status__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.facturileMele-table-status__indicator-separator {
  background-color: transparent !important;
}
/* FACTURI_SI_PLATI FACTURILE_MELE TABLE END */

/* DASHBOARD GRAFIC START */
.dashboard-grafic-months__control {
  margin-right: 16px;
  min-width: 183px;
  max-width: 183px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.dashboard-grafic-months__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard-grafic-months__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard-grafic-months__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard-grafic-months__indicator-separator {
  background-color: transparent !important;
}

.dashboard-grafic-tipFurnizare__control {
  min-width: 175px;
  max-width: 175px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.dashboard-grafic-tipFurnizare__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard-grafic-tipFurnizare__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard-grafic-tipFurnizare__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard-grafic-tipFurnizare__indicator-separator {
  background-color: transparent !important;
}

/* DASHBOARD GRAFIC END */

/* DASHBOARD FACTURI_TABLE START */

.dashboard_table_header-months-select__control {
  min-width: 183px;
  max-width: 183px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.dashboard_table_header-months-select__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard_table_header-months-select__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard_table_header-months-select__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard_table_header-months-select__indicator-separator {
  background-color: transparent !important;
}

.dashboard_table_header-tipFurnizare__control {
  min-width: 175px;
  max-width: 175px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.dashboard_table_header-tipFurnizare__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard_table_header-tipFurnizare__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard_table_header-tipFurnizare__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dashboard_table_header-tipFurnizare__indicator-separator {
  background-color: transparent !important;
}

/* DASHBOARD FACTURI_TABLE END */

/* LOC_DE_CONSUM ALL_LOCURI_DE_CONSUM TABLE START */

.locuriDeConsum-table-select__control {
  min-width: 175px;
  max-width: 175px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.locuriDeConsum-table-select__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.locuriDeConsum-table-select__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.locuriDeConsum-table-select__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.locuriDeConsum-table-select__indicator-separator {
  background-color: transparent !important;
}
/* LOC_DE_CONSUM ALL_LOCURI_DE_CONSUM TABLE END */

/* LOC_DE_CONSUM CONSUMURI_ORARE START */

.locDeConsum-consumOrar-month__control {
  min-width: 187px;
  max-width: 187px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.locDeConsum-consumOrar-month__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.locDeConsum-consumOrar-month__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.locDeConsum-consumOrar-month__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.locDeConsum-consumOrar-month__indicator-separator {
  background-color: transparent !important;
}

.locDeConsum-consumOrar-tipFurnizare__control {
  min-width: 149px;
  max-width: 149px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.locDeConsum-consumOrar-tipFurnizare__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.locDeConsum-consumOrar-tipFurnizare__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.locDeConsum-consumOrar-tipFurnizare__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.locDeConsum-consumOrar-tipFurnizare__indicator-separator {
  background-color: transparent !important;
}

/* LOC_DE_CONSUM CONSUMURI_ORARE END */

/* LOC_DE_CONSUM PROCESE_VERBALE TABLE START */

.procesVerbal-table-tip__control {
  min-width: 175px;
  max-width: 175px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.procesVerbal-table-tip__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.procesVerbal-table-tip__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.procesVerbal-table-tip__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.procesVerbal-table-tip__indicator-separator {
  background-color: transparent !important;
}

.procesVerbal-table-an__control {
  min-width: 111px;
  max-width: 111px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.procesVerbal-table-an__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.procesVerbal-table-an__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.procesVerbal-table-an__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.procesVerbal-table-an__indicator-separator {
  background-color: transparent !important;
}

/* LOC_DE_CONSUM PROCESE_VERBALE TABLE END */

/* SUPORT SOLICITARI TABLE START */

.suport-table-tipTichet__control {
  min-width: 157px;
  max-width: 157px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.suport-table-tipTichet__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-tipTichet__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-tipTichet__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-tipTichet__indicator-separator {
  background-color: transparent !important;
}

.suport-table-locDeConsum__control {
  min-width: 195px;
  max-width: 195px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.suport-table-locDeConsum__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-locDeConsum__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-locDeConsum__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-locDeConsum__indicator-separator {
  background-color: transparent !important;
}

.suport-table-tipFurnizare__control {
  min-width: 175px;
  max-width: 175px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.suport-table-tipFurnizare__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-tipFurnizare__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-tipFurnizare__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-tipFurnizare__indicator-separator {
  background-color: transparent !important;
}

.suport-table-status__control {
  min-width: 136px;
  max-width: 136px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.suport-table-status__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-status__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-status__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.suport-table-status__indicator-separator {
  background-color: transparent !important;
}

/* SUPORT SOLICITARI TABLE END */

/* RAPOARTE HEADER START */

.rapoarte_header-locDeConsum__control {
  min-width: 383px;
  max-width: 383px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
}
.rapoarte_header-locDeConsum__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.rapoarte_header-locDeConsum__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.rapoarte_header-locDeConsum__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  min-width: 383px;
  max-width: 383px;
  z-index: 2 !important;
}
.rapoarte_header-locDeConsum__indicator-separator {
  background-color: transparent !important;
}
/* RAPOARTE HEADER END */

/* RAPOARTE TAB DETALIAT START */

.rapoarte-grafic-detaliat-mare-header-curbaConsum-deLaLuna__control {
  min-width: 157px;
  max-width: 157px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-deLaLuna__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-deLaLuna__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-deLaLuna__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  z-index: 2 !important;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-deLaLuna__indicator-separator {
  background-color: transparent !important;
}

.rapoarte-grafic-detaliat-mare-header-curbaConsum-panaLaLuna__control {
  min-width: 171px;
  max-width: 171px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-panaLaLuna__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-panaLaLuna__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-panaLaLuna__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  z-index: 2 !important;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-panaLaLuna__indicator-separator {
  background-color: transparent !important;
}

.rapoarte-grafic-detaliat-mare-header-curbaConsum-optiuneAfisare__control {
  min-width: 195px;
  max-width: 195px;
  border-radius: 10px !important;
  border-color: #e8e0e0 !important;
  margin-right: 16px;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-optiuneAfisare__placeholder {
  color: #7e8488 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-optiuneAfisare__single-value {
  color: #1d1d1d !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-optiuneAfisare__menu {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  z-index: 2 !important;
}
.rapoarte-grafic-detaliat-mare-header-curbaConsum-optiuneAfisare__indicator-separator {
  background-color: transparent !important;
}

/* RAPOARTE TAB DETALIAT END */
